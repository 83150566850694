// LoginScreen.js
import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getLeadShow, getReturnCredit } from "../../../store/actions/lead";

export const CalendarEventsTooltip = (props) => {
  const { data, onSubmit, label, isFromCampaign } = props;

  const userInfo = useSelector((state) => state?.loginAuth?.user_details);


  const dispatch = useDispatch();
  const onSubmitHandler = () => {
    onSubmit();
  };

  const openNewLeadTab = () => {
    const dataToSend = {
      isView: true,
      leadData: {},
    };

    // Convert the data to a JSON string
    const jsonData = JSON.stringify(dataToSend);

    // Save the data to localStorage
    localStorage.setItem("newTabState", jsonData);

    // Open a new tab
    const newTab = window.open("/ShowLead", "_blank");

    if (newTab) {
      newTab.focus();
    }
  };

  const getLeadDetails = () => {
    dispatch(getLeadShow(data?.lead, openNewLeadTab));
    dispatch(getReturnCredit({ lead: data?.lead }));
  };

  function formatUtcTimeRange(dateTimeObject) {
    if (!dateTimeObject || !dateTimeObject.start || !dateTimeObject.end) {
      return "Invalid date-time range";
    }

    const start = moment(dateTimeObject.start).utc();
    const end = moment(dateTimeObject.end).utc();

    const formattedStart = start.format("hh:mm A");
    const formattedEnd = end.format("hh:mm A");

    return `From: ${formattedStart} - To: ${formattedEnd}`;
  }

  return (
    <>
      <Box
        sx={{
          padding: 0.5,
          marginTop: "0",
          backgroundColor: "#ffffff",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              component="h2"
              variant="h2"
              sx={{
                fontSize: 14,
                color: "#454647",
                fontWeight: 700,
                borderBottom: 1,
                borderColor: "#ddd",
                borderRadius: 1,
                padding: 0.5,
                backgroundColor: "#f1f1f1",
              }}
            >
              {label}
            </Typography>
            <Box
              sx={{
                margin: "8px",
                display: "flex",
                gap: "7px",
                alignItems: "center",
              }}
            >
              <Typography
                component="body"
                variant="body"
                onClick={() => {
                  getLeadDetails();
                }}
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#337ab7",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  cursor: "pointer",
                }}
              >
                {data.company_name !== "" ? data.company_name : "None"}
              </Typography>
            </Box>
            <Box
              sx={{
                margin: "8px",
                display: "flex",
                gap: "7px",
                alignItems: "center",
              }}
            >
              <Typography
                component="body"
                variant="body"
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#454647",
                }}
              >
                {formatUtcTimeRange(data) ?? "None"}
              </Typography>
            </Box>

            {userInfo?.user_type !== "Employee" && userInfo?.user_type !== "Client" && !isFromCampaign && (
              <Button
                variant="contained"
                sx={{
                  height: "30px",

                  pl: 1.5,
                  pr: 1.5,
                  pt: 1.5,
                  pb: 1.5,
                  backgroundColor: "#eb4034",
                  "&:hover": {
                    backgroundColor: "#eb4034",
                  },
                  borderRadius: "3px",
                  borderColor: "#eb4034",
                  color: "#fff",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  onSubmitHandler();
                }}
              >
                Delete
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

CalendarEventsTooltip.propTypes = {
  label: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isFromCampaign:false,
};

CalendarEventsTooltip.defaultProps = {
  label: "Timming",
  data: {},
  setValue: () => {},
  isFromCampaign:false,
};
